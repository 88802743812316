<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="category.title"
    :description="category.text"
    :progress="category.progress"
    ></SectionHeader>

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5" v-if="isLoading">
      <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
        <CategoryCardSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->

    <b-row class="main-container  py-5 px-md-5">
      <b-col sm="12" md="6" lg="4" xl="3"
        v-for="content in workRoles"
        v-bind:key="content.id"
        >
      <CategoryCard
        :img="getUrlImg(content.image)"
        :title="content.name"
        :text="content.description"
        :progress="calculateProgress(content.score, content.total_points)"
        :to="{ name: 'workRoleDetail', params: { name: content.name } }"
        class="mb-3"
      >
      </CategoryCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'
import SectionHeader from '@/components/SectionHeader'

import ProgressMixin from '@/services/mixins/progress.mixins'
import mapper from '@/services/mixins/mapper.mixins'

export default {
  name: 'Home',
  mixins: [ProgressMixin, mapper],
  components: { CategoryCard, SectionHeader, CategoryCardSkeleton },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getCategoryDetail: 'categories/getCategoryDetail',
      getCareerDetail: 'careers/getCareerDetail'
    })
  },
  computed: {
    ...mapGetters({
      category: 'categories/getCategoryDetail',
      career: 'careers/getCareerDetail',
      isLoading: 'careers/isLoading'
    }),
    workRoles () { return this.career.objectives || [] }
  },
  mounted () {
    this.getCareerDetail(this.$route.params.id)
  }
}
</script>

<style lang="scss" scoped>

</style>
